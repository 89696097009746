import {
  ItemContainer,
  Event,
  EventName,
  Icon,
  Title,
  RightArrow,
  SelectionName,
  ItemContainerInner,
} from 'CORE__UI/apps/PopularBets/core__popular-bet-createabet';
import styled from 'styled-components';
import { GREYS } from 'UI/globals/colours';

const Event_Ex = styled(Event)`
  color: ${GREYS.black};
`;

const ItemContainer_Ex = styled(ItemContainer)`
  background: ${GREYS.white};
  color: ${GREYS.black};
`;
const SelectionName_Ex = styled(SelectionName)`
  color: ${GREYS.black};
`;
const RightArrow_Ex = styled(RightArrow)`
  border-color: ${GREYS.silver};
`;
export {
  ItemContainer_Ex as ItemContainer,
  Event_Ex as Event,
  EventName,
  Icon,
  Title,
  RightArrow_Ex as RightArrow,
  SelectionName_Ex as SelectionName,
  ItemContainerInner,
};
